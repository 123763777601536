// API接口,用于定义请求接口的地址和其他配置

const protocol = window.location.protocol;
const domain = process.env.VUE_APP_API_HOST;
const prefix  = '';

const  apiOrigin = protocol + '//' + domain + prefix;

const WEB_CONFIG = {
    intServiceReqTimeout: 8000,
    urls: {
        baike: {
            jqbaike: apiOrigin + '/v1/baike/jqbaike',
            docDetail: apiOrigin + '/v1/baike/docDetail',
            cosmeticIndex: apiOrigin + '/v1/baike/cosmeticIndex',
        },

        common: {
            ossImageSign: apiOrigin + '/upload/imgSignature',
            ossVideoSign: apiOrigin + '/upload/ossSignature',
            ossVideoUrl: apiOrigin + '/video/getVideoUrl',
            ossSts: apiOrigin + '/upload/getststoken',

            overview: apiOrigin + '/Source/generalization', // 获取概况
        },
        product: {
            detail: apiOrigin + '/cosmetic/product/detail',
            search: apiOrigin + '/cosmetic/product/search',
            brandProduct: apiOrigin + '/cosmetic/product/brandProduct',
            category: apiOrigin + '/cosmetic/product/category',
        },
        makeup: {
            detail: apiOrigin + '/cosmetic/makeup/detail',
            search: apiOrigin + '/cosmetic/makeup/search',
            hot: apiOrigin + '/cosmetic/makeup/hot',
        },
        brand: {
            detail: apiOrigin + '/cosmetic/brand/detail',
            search: apiOrigin + '/cosmetic/brand/search',
        },
        search: {
            hot: apiOrigin + '/cosmetic/search/hot',
        },
        users: {

        },

    }
};

export default WEB_CONFIG;
