import md5 from 'js-md5'

export const unique = (arr) => {
    return Array.from(new Set(arr))
}
export const sort = (data) => {
    let keys  = Object.keys(data).sort();
    let newData = {};
    for (let i in keys) {
        newData[keys[i]] = data[keys[i]];
    }

    return newData;
}
//对数据进行签名，并放入到数据中
export const makeSign = (data) => {
    let post = data;
    // console.log(post)
    post.timestamp = parseInt((new Date()).getTime()/1000).toString();
    post.nance = Math.random()*1000000;
    post = sort(post);
    let signStr = JSON.stringify(post, (key, value) => {
        //将数字类型转成字符串，因为通过http传输后，后端接到的就是字符串了
        if (typeof value === 'number') {
            return value.toString()
        }
        return value;
    })
    signStr = "tkbd@_#!=" + signStr;
// console.log(signStr, post)

    post.sign = md5(signStr).toLocaleLowerCase();
    return post;
}

export const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
}).join('')
