import API from '../global/Resource';
import axios from 'axios';
import qs from 'qs';
import { Notify } from 'vant';
import DataStore from '../utils/DataStore';
import { makeSign } from '../utils/Utils'

const withCredentials = true;
const xsrfHeaderName = 'X-CSRF-TOKEN';
const timeout = API.intServiceReqTimeout;
const HTTP = axios.create({ withCredentials, xsrfHeaderName, timeout });

//添加请求拦截器
HTTP.interceptors.request.use(function (config) {
    const token = DataStore.storage.get('token');
    token && (config.headers.tokenid = token);

    if (typeof config.params !== 'undefined') {
        config.params = makeSign(config.params)
    }
    if (typeof config.data !== 'undefined') {
        config.data = makeSign(config.data)
        config.data = qs.stringify(config.data);
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});


// 添加响应拦截器
HTTP.interceptors.response.use(res => {
    if(res.data.code === 10010) {
        DataStore.storage.delete('token');
        window.location.reload();
        return;
    }

    if(res.data.code !== 1) {
        Notify({ type: 'danger', message: res.data.msg });
        return Promise.reject(res.data);
    }

    return res.data;
}, error => {
    // 对响应错误做点什么
    const hasErrorElm = !!document.querySelector('.global-error');
    if(!hasErrorElm) {
        Notify({ type: 'danger', message: '网络错误' });
        // Notification.error({
        //     title: '请求失败',
        //     message: '网络错误',
        //     customClass: 'global-error'
        // })
    }

    return Promise.reject(error);
});

/*
 * [请求库]
 * * @params url         { string }   @default => '' [接口地址，统一在Globala/Resource文件中]
 * * @params data/params { object }   @default => {} [发送数据]
 * * @params config      { object }   配置
 */
export default {
    post: function (url = '', data = {}, config) {
        return HTTP.post(url,  data, config);
    },

    put: function (url = '', data = {}, config) {
        return HTTP.put(url, data, config);
    },

    get: function (url, params = {}, config) {
        const OPTIONS = Object.assign({ params }, config);
        return HTTP.get(url, OPTIONS);
    },

    delete: function (url = '', params = {}, config) {
        const OPTIONS = Object.assign({ params }, config);
        return HTTP.delete(url, OPTIONS);
    }
};
